import * as React from "react";
import {
    Outlet,
    Link,
    NavLink,
    useLoaderData,
    Form,
    redirect,
    useNavigation,
    useSubmit,
  } from "react-router-dom";
import './homepage.css';

// import Campaign from './components/campaign/Campaign';
import Campaign, {
  loader as campaignsLoader, 
  action as campaignsAction
} from "../campaign/Campaign";

export function HomePage() {
  return (
    <>
    <Campaign />
      {/* <div className="div">
        <div className="div-2">
          <div className="column">
            <div className="div-3">
              <div className="div-4">
                <div className="div-5">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/7988863067fc7dacf38700cab25ad801249e47309b8988e7793078bf86d7366b?"
                    className="img-2"
                  />
                  <div className="div-6">
                    Run<span >first</span>
                  </div>
                </div>
                <div className="div-7">Welcome to ‘RunFast’</div>
              </div>
              <div className="div-8" />
              <div className="div-9">
                <div className="div-10">Where does running take you next?</div>
                <div className="div-11">
                  Our certified personal trainers create the perfect programs to
                  help you reach your goals, designed 100% around you. And
                  they’ll be cheering you on when you cross that finish line
                  like a badass, too.
                </div>
              </div>
            </div>
          </div>
          <div className="column-2">
            <div className="div-12">
              <div className="div-13">
                <div className="div-14">
                  <div className="div-15">
                    <Link to={`/`}>
                    Home
                    </Link>
                    </div>
                  <div className="div-16" />
                </div>
                <div className="div-17"><Link to={`/activity`}>Activity</Link></div>
                <div className="div-17"><Link to={`/campaign`}>Event</Link></div>
                <div className="div-18"><Link to={`/about`}>About</Link></div>
              </div>
              <div className="div-19">
                <div className="div-20">Pages</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/767a403266ec92adaf34eb6515a6adcf81636ef42f4c9a10e7270ab3d483ba1a?"
                  className="img-3"
                />
              </div>
              <Link to={`/profile`}>
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/3cb23c8e7d2f80a63e6cbe48bda449f688eb31ea7c6ff09d4b09070b265a648f?"
                    className="img-4"
                />
              </Link>
              
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

