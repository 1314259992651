import { useAuth } from "../hooks/useAuth";
import { Navigate } from "react-router-dom";
import { useJwt } from "react-jwt";

export const Secret = () => {
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <div>
      <h1>This is a Secret page</h1>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};
export const ProtectedRoute = ({ children }) => {
    const { user, isLoggedIn } = useAuth();
    if (!isLoggedIn) {
      // user is not authenticated
      return <Navigate to="/" />;
    }
    return children;
  };