import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter,
  Outlet
} from "react-router-dom";


import './index.css';
// import './App.css';

import ErrorPage from "./error-page";
import reportWebVitals from './reportWebVitals';
import LoginRedirect, {
  loader as loginLoader,
  action as loginAction,
} from './components/login/LoginRedirect';

import App from './App';
import { HomePage } from "./components/home/HomePage";

import Contact, {
  loader as contactLoader,
  action as contactAction,
} from "./routes/contact";

import EditContact, {
  action as editAction,
} from "./routes/edit";

import { action as destroyAction } from "./routes/destroy";

import Root, {
  loader as rootLoader,
  action as rootAction,
} from "./routes/root";

import Index from "./routes/index";
import Campaign, {
  loader as campaignsLoader, 
  action as campaignsAction
} from "./components/campaign/Campaign";

import CampaignDetail, {
  loader as campaignLoader, 
  action as campaignAction
} from "./components/campaign/CampaignDetail";

import Athlete, {
  loader as athleteLoader, 
  action as athleteAction
} from "./components/athlete/Athlete";

import Activity, {
  loader as activitiesLoader, 
  action as activitiesAction
} from "./components/activity/Activity";

import Profile, {
  loader as profileLoader, 
  action as profileAction
} from "./components/login/Profile";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { AuthProvider } from "./hooks/useAuth";


const router = createBrowserRouter([
  {
    path: "/",
    element: 
      <AuthProvider>
      <Root />
      </AuthProvider>,
    errorElement: <ErrorPage />,
    loader: rootLoader,
    action: rootAction,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <HomePage /> },
          {
            path: "campaign",
            // element: <Campaign />,
            element: (
              <>
                <Campaign />
                <Outlet />
              </>
            ),
            loader: campaignsLoader,
            action: campaignsAction,
            // children: [
            //   {
            //     path: ":campaignId",
            //     element: <CampaignDetail />,
            //     loader: campaignLoader,
            //     action: campaignAction,
            //   },
            // ],
          },
          {
            path: "campaign/:name/:campaignId",
            element: <CampaignDetail />,
            loader: campaignLoader,
            action: campaignAction,
          },
          {
            path: "activity",
            element: (
              <ProtectedRoute>
                <Activity />
                {/* <Outlet /> */}
              </ProtectedRoute>
            ),
            loader: activitiesLoader,
            action: activitiesAction,
          },
          // {
          //   path: "contacts/:contactId",
          //   element: <Contact />,
          //   loader: contactLoader,
          //   action: contactAction,
          // },
          // {
          //   path: "contacts/:contactId/edit",
          //   element: <EditContact />,
          //   loader: contactLoader,
          //   action: editAction,
          // },
          // {
          //   path: "contacts/:contactId/destroy",
          //   action: destroyAction,
          //   errorElement: <div>Oops! There was an error.</div>,
          // },
          {
            path: "athlete/:athleteId",
            element: <Athlete />,
            loader: athleteLoader,
            action: athleteAction,
          },
          {
            path: "login/connect/:providerName/redirect",
            element: <LoginRedirect />,
            loader: loginLoader,
            action: loginAction,
            errorElement: <div>Oops! There was an error.</div>,
          },
          {
            path: "profile",
            // element: <Profile />,
            element: 
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>,
            loader: loginLoader,
            action: loginAction,
            errorElement: <div>Oops! There was an error.</div>,
          },
          /* the rest of the routes */
        ],
      },
    ],
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
