import { useState, useEffect } from 'react';

import Campaign from './components/campaign/Campaign';
// import './App.css';


function App() {

  return (
    <div className="app">
      <main>
        <Campaign />
      </main>
    </div>
  )
}
export default App;