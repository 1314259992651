import { useState, useEffect } from 'react';
import CampaignItem from './CampaignItem';
import './Campaign.css';

export async function loader({ params }) {
    return { };
}

export async function action({ request, params }) {
}

function Campaign() {
  const [campaigns, setCampaign] = useState([]);
  const [newTodo, setNewTodo] = useState("");

  useEffect(() => {
    // update update the list of todos
    // when the component is rendered for the first time
    update();
  }, []);

  // This function updates the component with the
  // current todo data stored in the server
  function update() {
    fetch(`${process.env.REACT_APP_BACKEND}/api/run-campaigns?populate[0]=banner&sort[0]=start_date:desc`)
      .then(res => res.json())
      .then(campaign => {
        setCampaign(campaign.data);
      })
  }

  return (
    <div className='container margin-t'>
    <h4 className="section-title">The Opening Races</h4>
    <div className='races-wrapper row'>
        {
        campaigns.map((campaign, i) => {
            return <CampaignItem campaign={campaign} key={campaign.id + '_' + i} update={update} />
        })
        }
    </div>
    </div>
  )
}
export default Campaign;