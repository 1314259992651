import React, { useEffect, useState } from 'react';
import { useHistory, 
    useLocation, useParams,
    redirect,
    useNavigate
 } from "react-router-dom";
 import { useAuth } from "../../hooks/useAuth";
const backendUrl = process.env.REACT_APP_BACKEND;

export async function loader({ params }) {
    console.log('params', params)
    return {}
}
export async function action({ request, params }) {
    console.log('request, params', request, params)
}

const LoginRedirect = (props) => {
  const [text, setText] = useState('Loading...');
  const { login } = useAuth();
  const location = useLocation();
  const params = props.params || 'strava';
  let { providerName } = useParams();

  useEffect(() => {
    //http://localhost:1337/api/strava-provider/auth/strava/callback
    // Successfully logged with the provider
    // Now logging with strapi by using the access_token (given by the provider) in props.location.search
    fetch(`${backendUrl}/api/strava-provider/auth/${providerName}/callback${location.search}`)
      .then(res => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then(res => res.json())
      .then(res => {
        // Successfully logged with Strapi
        // Now saving the jwt to use it for future authenticated requests to Strapi
        // setIsLogged(res.jwt);
        login(res)
        // localStorage.setItem('username', res.user.username);
        // localStorage.setItem('user', JSON.stringify(res.user));
        setText('You have been successfully logged in. You will be redirected in a few seconds...');
        // setTimeout(() => {
        //   console.log('redirect')
        //   // navigate('/')
        //   navigate("/", { replace: true });
        //   // navigate(0)
        // }, 500); // Redirect to homepage after 3 sec
      })
      .catch(err => {
        console.log(err);
        setText('An error occurred, please see the developer console.')
      });
  }, [location.search, params.providerName]);

  return <p>{text}</p>
};

export default LoginRedirect;