import React, { useEffect, useState } from 'react';
import { 
  useHistory, 
  useLocation, 
  useParams,
  redirect,
  useLoaderData
 } from "react-router-dom";
 import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND;

export async function loader({ params }) {
    console.log('params', params)
    const activity = await getData(params);
    if (!activity) {
      throw new Response("", {
        status: 404,
        statusText: "Not Found",
      });
    }
    return { activity, id: params.campaignId};
}
export async function action({ request, params }) {
    console.log('request, params', request, params)
}
function getData(params){
  return fetch(`${backendUrl}/api/strava-provider/query`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
    },
  })
  .then(res => {
    if (res.status !== 200) {
      throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
    }
    return res;
  })
  .then(res => res.json())
  .then(res => {
    // setActivity(res);
    return res;
  })
  .catch(err => {
    console.log(err);
    // setText('An error occurred, please see the developer console.')
  });
}
const Profile = (props) => {
  // const [activity, setActivity] = useState([]);
  // let { providerName } = useParams();
  const { activity } = useLoaderData([]);
//   const history = useHistory();

  useEffect(() => {
    // Successfully logged with the provider
    // Now logging with strapi by using the access_token (given by the provider) in props.location.search
    
  });
  return <div className="race-distances">
  {
    activity && activity.map((a, i) => {
      return <span className="badge badge-distance">Id: {a.id} - StravaId: {a.stravaId}</span>
    })
  }
</div>
};

export default Profile;