import { useState, useEffect } from 'react';
import { Avatar, List, Radio, Space, Card } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import {
  Outlet,
  Link,
  NavLink,
  useLoaderData,
  Form,
  redirect,
  useNavigation,
  useSubmit,
} from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { useJwt } from "react-jwt";

import { round } from '../../utils/format';
import ActivityItem from '../activity/ActivityItem';
// import './Activity.css';

const { Meta } = Card;

const backendUrl = process.env.REACT_APP_BACKEND;

export async function loader({ params }) {
  return { athleteId: params.athleteId};
}

export async function action({ request, params }) {
}

function Athlete() {
  const { athleteId } = useLoaderData();
  const [activities, setActivity] = useState({});
  const [summary, setSummary] = useState({});
  const [text, setText] = useState('Loading...');
  const { user, isLoggedIn} = useAuth();

  useEffect(() => {
    // update update the list of todos
    // when the component is rendered for the first time
    // getAthleteSummary(athleteId);
    getAthleteActivities(athleteId);
  }, [athleteId]);
  function getAthleteActivities(athleteId) {
    ///api/strava-provider/athlete/:athleteId/activity
    fetch(`${backendUrl}/api/strava-provider/athlete/${athleteId}/activity?pagination[page]=1&pagination[pageSize]=100`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(res => {
      if (res.status !== 200) {
        throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
      }
      return res;
    })
    .then(res => res.json())
    .then(res => {
      setActivity(res);
    })
    .catch(err => {
      console.log(err);
      setText('An error occurred, please see the developer console.')
    });
  }
  function getAthleteSummary(athleteId) {
    fetch(`${backendUrl}/api/strava-provider/athlete/${athleteId}/summary`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(res => {
      if (res.status !== 200) {
        throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
      }
      return res;
    })
    .then(res => res.json())
    .then(res => {
      setSummary(res);
    })
    .catch(err => {
      console.log(err);
      setText('An error occurred, please see the developer console.')
    });
  }
  const totalDistance = activities?.summary?.distance || 0;
  return (
    <div className='container margin-t'>
    <h4 className="section-title">Các hoạt động</h4>
    <Card
    style={{ width: "100%" }}
    // cover={<img alt="example" src={`${REACT_APP_BACKEND}${campaign.banner.data[0].attributes.url}`} />}
    >
    <div className='races-wrapper row'>Tổng hoạt động trong tháng: {round(totalDistance/1000)} km</div>
    </Card>
    <div className='races-wrapper row'>
        {
        activities?.data?.map((activity, i) => {
            return <ActivityItem activity={activity} key={i} />
        })
        }
          {/* <List
          // pagination={{ position, align }}
          dataSource={activities}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                // avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                title={item.name}
                description="Ant Design, a design language for background applications, is refined by Ant UED Team"
              />
            </List.Item>
          )}
        /> */}
    </div>
    </div>
  )
}
export default Athlete;